<template>
  <router-view></router-view>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center mt-5">Customer Product Request</h2>
        <h3 class="text-center mb-4">Customer Info</h3>
        <form>

          <div class="row">
            <div class="col-md-6">
              <h5>Name<span class="text-danger">*</span></h5>
              <input class="form-control" type="text" v-model="productRequestInfo.name" placeholder="Name *" />
            </div>

            <div class="col-md-6">
              <h5>Phone Number<span class="text-danger">*</span></h5>
              <input class="form-control" type="text" v-model="productRequestInfo.phone_number"
                placeholder="Phone number *" />
            </div>

            <div class="col-md-6">
              <h5>Email</h5>
              <input class="form-control" type="text" v-model="productRequestInfo.email" placeholder="Email" />
            </div>

            <div class="col-md-6">
              <h5>Location<span class="text-danger">*</span></h5>
              <select class="form-control-select" v-model="productRequestInfo.location">
                <option value="Omaha, NE">Omaha, NE</option>
                <option value="Des Moines, IA">Des Moines, IA</option>
                <option value="Highlands Ranch, CO">Highlands Ranch, CO</option>
                <option value="Parker, CO">Parker, CO</option>
              </select>
            </div>
            <div class="col-md-6">
              <h5>Request Details</h5>
              <textarea class="form-control" rows="3" v-model="productRequestInfo.request_details"></textarea>
            </div>
            <div class="col-md-6">
              <h5>Any Specific Brand</h5>
              <textarea class="form-control" rows="3" v-model="productRequestInfo.specific_brand"></textarea>
            </div>
            <div class="col-md-6">
              <h5>Required By</h5>
              <input class="form-control" type="date" placeholder="Date" v-model="productRequestInfo.required_by" />
            </div>
          </div>


          <div class="text-center">
            <button class="btn submit-btn" type="button" @click="saveProductRequestInfo()"
              :disabled="!productRequestInfo.name || !productRequestInfo.phone_number || !productRequestInfo.location || isDisabled">Submit</button>
          </div>



        </form>




      </div>
    </div>
  </div>

</template>

<script>


// import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
export default {
  name: "ProductRequest",
  data() {
    return {
      productRequestInfo: {
        name: "",
        phone_number: "",
        email: "",
        location: "",
        request_details: "",
        specific_brand: "",
        required_by: ""
      },
      isDisabled: false
    }
  },
  methods: {
    saveProductRequestInfo() {
      if (this.productRequestInfo.phone_number.length < 10) return alert("Phone number should have at least 10 digits")
      this.isDisabled = true;
      this.axios.post("https://admin.konarkgrocers.net/customer-product-requests", { ...this.productRequestInfo }).then(res => {
        this.isDisabled = false;
        if (res.status === 200) {
          this.resetFormInputs();
          this.$router.push("/thank-you");
        }
      }, error => {
        this.isDisabled = false;
        alert(error.response.data.error.message);
      })
    },

    // resets form inputs
    resetFormInputs() {
      this.productRequestInfo = {
        name: "",
        phone_number: "",
        email: "",
        location: "",
        request_details: "",
        specific_brand: "",
        required_by: ""
      }
    }
  },
  // validations: {
  //   productRequestInfo: {
  //     name: {
  //       required
  //     },
  //     phone_number: {
  //       required,
  //       minLength: minLength(10),
  //       maxLength: maxLength(13)
  //     },
  //     email: {
  //       email
  //     },
  //     location: {
  //       required
  //     }
  //   }
  // }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

html,
body {
  font-family: 'Roboto', sans-serif;
}

h5 {
  text-align: left;
  padding-top: 10px;
  font-size: 18px;
}

a {
  color: #42b983;
}

/* .btn-primary{
  background-color: #3ba2f4;
  padding: 2rem;
  border: none;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    text-transform: uppercase;
} */
form {
  /* width: 20%; */
  margin-bottom: 20px;
  box-shadow: 3px 3px 8px #00000029;
  padding: 20px;
  border: 1px solid lightgray
}

.form-control {
  /* height: 30px !important; */
  padding: 10px;
  margin: 10px 0;
  /* display: flex; */
  margin: 0 auto;
  margin-bottom: 40px;
  /* width: 270px; */
  border-radius: 4px;
  /* box-shadow: 3px 3px 8px #00000029; */
  /* border: 1px solid black; */
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  /* border:1px solid black */
}

.form-control-select {
  width: 100%;
  margin-bottom: 40px;
  /* height: 50px;
    width: 295px; */
  padding: 10px;
  border-radius: 4px;
  /* box-shadow: 3px 3px 8px #00000029; */
  border: 1px solid #ced4da;
  /* border: 1px solid black; */
}

.form-control-select:focus-visible {
  outline: none !important;
}

.submit-btn {
  height: 50px;
  width: 300px;
  font-size: 22px;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to right, #f85800,
      #7f9900) !important;
}
</style>
