<template>

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center mt-5">Customer FeedBack Form</h2>

        <div class="bar"></div>
        <h3 class="text-center mb-4">Customer Info</h3>
        <form>

          <div class="row">
            <div class="col-md-6">
              <h5>Name<span class="text-danger">*</span></h5>
              <input class="form-control" type="text" placeholder="Name *" v-model="feedbackInfo.name" />
            </div>

            <div class="col-md-6">
              <h5>Phone Number<span class="text-danger">*</span></h5>
              <input class="form-control" type="text" placeholder="Phone number *"
                v-model="feedbackInfo.phone_number" />
            </div>

            <div class="col-md-6">
              <h5>Email</h5>
              <input class="form-control" type="text" placeholder="Email" v-model="feedbackInfo.email" />
            </div>

            <div class="col-md-6">
              <h5>Location<span class="text-danger">*</span></h5>
              <select class="form-control-select" v-model="feedbackInfo.location">
                <option value="Omaha, NE">Omaha, NE</option>
                <option value="Des Moines, IA">Des Moines, IA</option>
                <option value="Highlands Ranch, CO">Highlands Ranch, CO</option>
                <option value="Parker, CO">Parker, CO</option>
              </select>
            </div>

            <div class="col-md-6">
              <h5>FeedBack Type</h5>
              <select class="form-control-select" v-model="feedbackInfo.feedback_type">
                <option value="Service Issue">Service Issue</option>
                <option value="Payment Issue">Payment Issue</option>
                <option value="Product Issue">Product Issue</option>
              </select>
            </div>

            <div class="col-md-6">
              <h5>Issue Date </h5>
              <input class="form-control" type="date" placeholder="Issue Date" v-model="feedbackInfo.issue_date" />
            </div>
            <div class="col-md-6">
              <h5>Feedback Details </h5>
              <textarea class="form-control" rows="3" v-model="feedbackInfo.feedback_details"></textarea>
            </div>

            <div class="col-md-6">
              <h5>How Do you Rank Us </h5>
              <!-- <input class="form-control" type="number" placeholder="Rate between 0 to 10"
                v-model="feedbackInfo.rank" /> -->
              <star-rating v-model:rating="feedbackInfo.rank"></star-rating>
            </div>

          </div>

          <div class="text-center">
            <button class="btn submit-btn" type="button"
              :disabled="!feedbackInfo.name || !feedbackInfo.phone_number || !feedbackInfo.location || isDisabled"
              @click="saveFeedbackInfo()">Submit</button>
          </div>

        </form>



      </div>
    </div>
  </div>

</template>

<script>
import StarRating from 'vue-star-rating'
export default {
  name: "CustomerFeedback",
  data() {
    return {
      feedbackInfo: {
        name: "",
        phone_number: "",
        email: "",
        location: "",
        feedback_type: "",
        issue_date: "",
        feedback_details: "",
        rank: null
      },
      isDisabled: false
    }
  },
  components: {
    StarRating
  },
  methods: {
    saveFeedbackInfo() {
      if (this.feedbackInfo.phone_number.length < 10) return alert("Phone number should have at least 10 digits")
      this.isDisabled = true;
      this.axios.post("https://admin.konarkgrocers.net/customer-feedback-infos", { ...this.feedbackInfo }).then(res => {
        this.isDisabled = false;
        if (res.status === 200) {
          this.resetFormInputs();
          this.$router.push("/thank-you");
        }
      }, error => {
        this.isDisabled = false;
        alert(error.response.data.error.message);
      })
    },

    // resets form inputs
    resetFormInputs() {
      this.feedbackInfo = {
        name: "",
        phone_number: "",
        email: "",
        location: "",
        feedback_type: "",
        issue_date: "",
        feedback_details: "",
        rank: null
      }
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html,
body {
  font-family: 'Poppins', sans-serif !important;
}

/* .bar{
  margin:auto 0;
  height:2px;
  width:20px;
  background-color: black;
} */
h5 {
  text-align: left;
  padding-top: 10px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif !important;
}

h2,
h3 {
  font-family: 'Poppins', sans-serif !important;
}

a {
  color: #42b983;
  font-family: 'Poppins', sans-serif !important;
}

/* .btn-primary{
  background-color: #3ba2f4;
  padding: 2rem;
  border: none;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    text-transform: uppercase;
} */
form {
  /* width: 20%; */
  margin-bottom: 20px;
  box-shadow: 3px 3px 8px #00000029;
  padding: 20px;
  border: 1px solid lightgray;
  font-family: 'Poppins', sans-serif !important;
}

.form-control {
  /* height: 30px !important; */
  padding: 10px;
  margin: 10px 0;
  /* display: flex; */
  margin: 0 auto;
  margin-bottom: 40px;
  /* width: 270px; */
  border-radius: 4px;
  font-family: 'Poppins', sans-serif !important;
  /* box-shadow: 3px 3px 8px #00000029; */
  /* border: 1px solid black; */
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  /* border:1px solid black */
}

.form-control-select {
  width: 100%;
  margin-bottom: 40px;
  /* height: 50px;
    width: 295px; */
  padding: 10px;
  border-radius: 4px;
  /* box-shadow: 3px 3px 8px #00000029; */
  border: 1px solid #ced4da;
  font-family: 'Poppins', sans-serif !important;
  /* border: 1px solid black; */
}

.form-control-select:focus-visible {
  outline: none !important;
}

.submit-btn {
  height: 50px;
  width: 300px;
  font-size: 22px;
  text-transform: uppercase;
  background: linear-gradient(to right, #f85800,
      #7f9900) !important;
  font-family: 'Poppins', sans-serif !important;
  color: white;
}
</style>
