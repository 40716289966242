<template>
  <router-view />
</template>


<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap'); */

body{
  font-family: 'Poppins', sans-serif  !important;
  /* font-family: 'Lato', sans-serif !important; */
}
</style>