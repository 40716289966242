<template>

  <div class="thankyou">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2 class="text-center">Thank You for your Request , it will be reviewed and responded with in 1 business day.
          </h2>
          <br />
          <router-link to="/">
            <button class="btn" type="button">Go To Home</button>
          </router-link>

        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
h2 {
  padding-top: 4rem;
  color: white
}

.thankyou {
  margin: 0 auto;

  background-image: linear-gradient(rgba(22, 22, 22, 0.8), rgba(22, 22, 22, 0.8)), url('https://cdn.pixabay.com/photo/2018/11/12/18/44/thanksgiving-3811492_1280.jpg');
  background-size: cover;
  height: 100vh;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-top: 10rem;
}

.btn {
  height: 50px;
  width: 300px;
  font-size: 22px;

  color: white;
  margin: 0 auto;
   background:linear-gradient(to right, #f85800,
#7f9900)  !important;
}
</style>