<template>

 <div class="home-page">
     <div class="container text-center">
    <div class="row">
      <div class="col-md-12">

        <img src="../assets/kg-logo.png" class="img-fluid">
        <h1 class="mb-4 action-button">Customer Portal</h1>

        <div class="row ">
          <div class="col-md-4 offset-md-2">
             
           
  <router-link to="/productrequest">
            <button class="btn" type="button">
              Product Request 
              
            </button>
          </router-link>
          </div>
 <!-- <button @click="click()">Login</button> -->
          <div class="col-md-4">
             
  <router-link to="/customerfeedback">

            <button class="btn" type="button">
              Customer Feedback
            </button>
          </router-link>
          </div>
        </div>

      
      
      </div>
    </div>
  </div>
 </div>






</template>



<script>


// methods : {
//   redirectToLogin(){
//    router.push({ path: '/shop/customer/login'})
//   }
// }
// import HelloWorld from './components/HelloWorld.vue'

// import router from './router'
// import { useRouter } from 'vue-router'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  methods : {
  redirectToLogin(){
     this.$router.push('/customerfeedback');
  // router.push({ path: '/customerfeedback'})
  }
},

}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

html, body {
  font-family: 'Roboto', sans-serif;
}
#app {
    font-family: 'Roboto', sans-serif;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}





.home-page {
  margin:0 auto;
  
  /* background-color: black; */
  /* background-image:linear-gradient(rgba(22,22,22,0.8),rgba(22,22,22,0.8)), url('https://cdn.pixabay.com/photo/2017/06/06/22/37/italian-cuisine-2378729_1280.jpg'); */
      background-size: cover;
    height: 100vh;
    background-position: bottom;
    background-repeat: no-repeat;
    padding-top: 6rem;
}
.action-button{
   padding-top: 3rem;
}

h1{color:black; font-weight: 600;}
.btn {
  height: 65px !important;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 21px !important;
  background:linear-gradient(to right, #f85800,
#7f9900)  !important;
  border-radius: 8px !important;
  color: white
}

.btn:hover {
  color: white
}

a {
  color: white !important;
  text-decoration: none !important;
}
</style>

