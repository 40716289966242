import { createApp } from "vue";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.min.css";
// import Vue from 'vue'
// import VueRouter from 'vue-router'
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router/routes";
// import routes from './routes';
import VueStarRating from "vue-star-rating";

createApp(App)
  .use(router)
  .use(VueAxios, axios)
  .use(VueStarRating)
  .mount("#app");

// Vue.use(VueRouter)
