// import { createApp } from 'vue'
// import App from './App.vue'
// import "bootstrap/dist/css/bootstrap.min.css"
// import Vue from 'vue'/
// import VueRouter from 'vue-router'
import { createWebHistory, createRouter } from "vue-router";
import ProductRequest from "../components/ProductRequest.vue";
import CustomerFeedback from "../components/CustomerFeedback.vue";
import ThankYou from "../components/ThankYou";
import HomePage from "../components/HomePage";

// createApp(App).mount('#app')
// Vue.use(VueRouter)

const routes = [ 
   { path: "/", component: HomePage, },
  { path: "/productrequest", component: ProductRequest },
  { path: "/customerfeedback", component: CustomerFeedback },
  { path: "/thank-you", component: ThankYou }
];
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
